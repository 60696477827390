<template>
    <b-card>
      <ModalDepreciableAssets :editable="editable" :asset_id="$route.params.id"/>
    </b-card>
  </template>
  
<script>
  import {
    BCard,
  } from 'bootstrap-vue';
  import ModalDepreciableAssets from './ModalDepreciableAssets.vue';
  export default {
      components: {
          BCard,
          ModalDepreciableAssets
      },
      data(){
          return{
          editable: true
      }}
  }
</script>
